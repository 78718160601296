import { render, staticRenderFns } from "./AfterSaleInfo.vue?vue&type=template&id=0bd1db8b&scoped=true&"
import script from "./AfterSaleInfo.vue?vue&type=script&lang=js&"
export * from "./AfterSaleInfo.vue?vue&type=script&lang=js&"
import style0 from "./AfterSaleInfo.vue?vue&type=style&index=0&id=0bd1db8b&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0bd1db8b",
  null
  
)

export default component.exports